import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Dropdown } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';


const Projects = ({ ProjectsDetails, slug}) => {

    const [locationData, setlocationData] = React.useState('');
    const [projectData, setprojectData] = React.useState({});
    const [scroll, setscroll] = React.useState(false);
    const initial_ref = React.useRef(true);

    React.useEffect(() => {
        if (initial_ref.current) {
            initial_ref.current = false;
            if(slug === 'farm'){
                setlocationData(ProjectsDetails.location_list[2].location_name);
            }else{
                setlocationData(ProjectsDetails.location_list[0].location_name);
            }

            
            
            setprojectData(ProjectsDetails.section_card.filter((item) => {

                return item.location_id == ProjectsDetails.location_id
            }));

        }
    }, [locationData])


    const handleItem = (id, item) => {
        //console.log("item",item)
        setprojectData(ProjectsDetails.section_card.filter((item) => {
            return item.location_id == id
        }));
        setlocationData(item);
        setscroll(true);
    }

    React.useEffect(() => {
        if (scroll) {
            window.scrollTo(0, 700)
            console.log("hi");
            setscroll(false);

        }
    }, [scroll])

    

    return (
        <section className='margin-top'>
            <Container className='category_projects'>
                <Row>
                    <Col lg={8} className="col-6">
                        <h2 className='featured_projects_title'>{ProjectsDetails.title} {locationData}</h2>
                    </Col>
                    <Col lg={4} className="col-6">
                        {/* <hr className='projects-hr' /> */}
                        <div className='featured-projects-dropdown'>
                            <Dropdown
                            >
                                <Dropdown.Toggle style={{ border: "1px solid red !important" }} className='dropbtn'>{locationData}</Dropdown.Toggle>
                                <Dropdown.Menu >
                                    {
                                        ProjectsDetails.location_list.map((Element, index) => {
                                            return (
                                                <Dropdown.Item key={index} as="button" onClick={() => handleItem(Element.location_id, Element.location_name)}>
                                                    {Element.location_name}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                    </Col>
                </Row>
                <Row>

                    {
                        Object.keys(projectData).length > 0 && projectData.map((Element, index) => {
                            return (
                                <Col lg={6} key={index} sm={6} style={{ position: "relative" }}>
                                    <p className='btn-get-started progresss'>{Element.status}</p>
                                    
                                        <Card style={{ border: "none" }}>
                                            <LinkContainer to={'/project/' + Element.slug}>
                                              <img src={Element.feature_img} className='project_img' style={{backgroundSize: 'cover'}} alt="" />
                                            </LinkContainer>
                                            <Card.Body>
                                                <Row>
                                                        <Col lg={7}>
                                                                <Card.Text className='welcome_decription card_title'>
                                                                        {Element.title}
                                                                </Card.Text>
                                                        </Col>
                                                        <Col lg={5}>
                                                            {Element.slug == 'aspiration' && 
                                                            <a href='https://aspiration-booking-showcase-lite.azurewebsites.net/site-map-booking' target="_blank" rel='noreferrer'>
                                                                <Card.Text className='btn-get-started view-details book-now'>Book Now</Card.Text>
                                                            </a>
                                                            }
                                                            { Element.slug == 'ira-the-square' && 
                                                            <a href='https://square-villa-booking-showcase-lite.azurewebsites.net/site-map-booking' target="_blank" rel='noreferrer'>
                                                                <Card.Text className='btn-get-started view-details book-now'>Book Now</Card.Text>
                                                            </a>
                                                            }
                                                        </Col>
                                                </Row>
                                                <Row>
                                                        <Col lg={7}>
                                                                {Element.location !== '' &&
                                                                <Card.Text className='welcome_decription card-location'>
                                                                        <img src='/assets/location-icon.jpg' />
                                                                        &nbsp;<span>{Element.location}</span>
                                                                </Card.Text>
                                                                }
                                                                
                                                        </Col>
                                                        <Col lg={5}>
                                                        {Element.feature !== '' &&
                                                                <Card.Text className='welcome_decription card-location'>
                                                                        <img src='/assets/dimensions-icon.jpg' />
                                                                        &nbsp;<span>{Element.feature}</span>
                                                                </Card.Text>
                                                        }
                                                                
                                                        </Col>
                                                </Row>
                                                <Row className='mt-3'>
                                                        <Col lg={7}>
                                                        {Element.unit_size !== '' &&
                                                                <Card.Text className='welcome_decription'>
                                                                Unit Size :
                                                                <span style={{ fontWeight: 500, color: "#383838" }}>&nbsp;{Element.unit_size}</span>
                                                        </Card.Text>
                                                        }
                                                                
                                                        </Col>
                                                        <Col lg={5}>
                                                                {Element.level !== '' &&
                                                                        <Card.Text className='welcome_decription'>
                                                                        Luxury Level :
                                                                        <span style={{ fontWeight: 500, color: "#383838" }}>{" "}{Element.level}</span>
                                                                        </Card.Text>
                                                                }
                                                                
                                                        </Col>
                                                </Row>

                                                <Row>
                                                        <Col lg={6}>

                                                                <LinkContainer to={'/project/' + Element.slug}>
                                                                        <Card.Text className='btn-get-started view-details'>View Project Details</Card.Text>
                                                                </LinkContainer>
                                                        </Col>
                                                        {Element.brochure_link !== '' &&
                                                                <Col lg={6}>
                                                                        <a href={Element.brochure_link} target="_blank" style={{ textDecoration: 'none' }}>
                                                                                <Card.Text className='btn-get-started view-details1' style={{}}>
                                                                                        Download Brochure
                                                                                        &nbsp;<img src="/assets/download-icon.jpg" />
                                                                                </Card.Text>
                                                                        </a>
                                                                </Col>
                                                        }

                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    
                                </Col>
                            )
                        })
                    }


                </Row>
            </Container>
        </section >

    );
};

export default Projects;